import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from '@api/Apis';
import { useRouter } from 'next/router';
import parse from 'html-react-parser';
const ProvenComponent = ({ productDetailContent, costtype }) => {
    const FIND_ID = costtype?.find((row) => row?.id);

    const [provenContent, setProvenContent] = useState([]);
    const [headingData, setHeadingData] = useState([]);
    const [innerLoader, setInnerLoader] = useState(false);
    const router = useRouter();
    const store = (router?.query?.page) ? router?.query?.page : 'us';

    const wrapSvgWithLink = (parsedSvg, link, onSvgClick) => {
        const handleSvgClick = (event) => {
            if (onSvgClick) {
                onSvgClick(event);
            }
        };

        return (
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleSvgClick}
            >
                {React.cloneElement(parsedSvg, { onClick: handleSvgClick })}
            </a>
        );
    };

    useEffect(() => {
        getProvenBanifitContent();
    }, [router?.asPath, costtype]);

    function getProvenBanifitContent() {
        setInnerLoader(true);
        api.getProvenBanifitContent({ product_id: FIND_ID?.id, store: store }).then((res) => {
            if (res?.status === 200 && res?.data?.code === 1) {
                if (res?.data?.main_page_content?.length > 0) {
                    setHeadingData(res?.data?.main_page_content[0]);
                }
                if (res?.data?.content?.length > 0) {
                    setProvenContent(res?.data?.content);
                }
            }
            setInnerLoader(false);
        }).catch((err) => {
            console.log(err);
            setInnerLoader(false);
        });
    }
    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow custom-next" onClick={onClick}>
            <div className="next-arrow slick-arrow">
                <img src="/images/Arrowright.png" />
            </div>
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow custom-prev" onClick={onClick}>
            <div className="prev-arrow slick-arrow">
                <img src="/images/Arrowleft.png" />
            </div>
        </div>
    );
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div className='proven-inner'>
            {innerLoader && <p>Loading...</p>}
            {!innerLoader && (
                <div className='proven-title'>
                    <h2>{productDetailContent?.id === 7 || productDetailContent?.id === 19 ? "Benefits" : "Proven Benefits"}</h2>
                    <p style={{ fontSize: "14px" }}><b>{productDetailContent?.id !== 7 && productDetailContent?.id !== 19 && "(Click Image)"}</b></p>
                    <Slider {...sliderSettings}>
                        {provenContent.map((val, index) => {
                            const parsedSvg = val.svg_image ? parse(val.svg_image) : null;
                            const svgWithLink = wrapSvgWithLink(parsedSvg, productDetailContent?.id !== 7 && productDetailContent?.id !== 19 && val?.link);

                            return (
                                <div className="inner-icon" key={index}>
                                    {svgWithLink}
                                    {val.heading ? parse(val.heading) : ""}
                                    {/* <a href={val?.link} target='blank'>Leassrn More</a> */}
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            )}
        </div >
    );
};

export default ProvenComponent;
