import styled from "styled-components";

const HomeComponent = styled.div`
@font-face {
    font-family: "Graphik";
    src: url("Graphik.woff2") format("woff2");
    font-display: swap;
}


  && .silck-slide-home .slick-slider.slick-initialized {
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
  }
  .without_animation {
    display: none;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list .product-main {
    margin: 0;
  }
.banner-slider {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;

    .slick-slider {
      height: 100%;
      width: 100%;
      div{
        height: 100%;
        width: 100%;

        img {
          position: static;
          max-width: 100%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  div#media {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }
  .home-page .container {
    width: 100% !important;
    max-width: 1570px !important;
    min-width: unset;
    @media (max-width:1600px){
      max-width: 1340px !important;
    }
    @media (max-width:1365px){
      max-width: 1170px !important;
    }
    @media (max-width:1200px){
      max-width: 970px !important;
    }
    @media (max-width:991px){
      max-width: 740px !important;
    }
    @media (max-width:767px){
      max-width: 540px !important;
    }
    @media (max-width:575px){
      max-width: 100% !important;
    }
  }
  .par-text {
    width: 100%;
    margin: 60px auto;
    max-width: 1234px;
  }
  .par-welcome {
    margin-bottom: 32px;
    h4 {
      font-size: 18px;
    }
  }
  /* .home-page {
    background-size: 100% 100%;
  } */
  .welcome-section {
    .text-welcome {
      /* transform: translateY(51px); */
      z-index: 1;
      position: relative;
    }
    .first_section_ {
      display: flex;
      .text-welcome {
        /* transform: translateY(51px); */
        z-index: 1;
        position: relative;
        .welcome-text {
          transform: translateX(53px);
        }
        h2 {
          padding: 50px 33px 0 0;
          font-size: 59px;
        }
      }
    }
    .welcome_banner {
        position: relative;
        background-color: #c2cfde;
        .container {
            position: static;
        }
       .left-side-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            img{ 
              height: 100%;
              object-fit: cover;
              @media (max-width:1600px){
                /* object-position: 80% 100%; */
              }
            }
            @media (max-width:1200px){
              position: static;
              width: 100%;
              flex: 1 1 100%;
            }
        }
        .right-side-text {
            width: 50%;
            max-width: 50%;
            margin-right: 0;
            margin-left: auto;
            display: flex;
            align-items: center;
            @media (max-width:1200px){
              width: 100%;
              flex: 1 1 100%;
              max-width: 100%;
            }
        }
        .container {
            min-height: 646px;
        .main-banner-section {
              min-height: 646px;
            }
        }
        .inside-right-sec {
          h2 {
            max-width: 460px;
            letter-spacing: 0;
            @media (max-width:1200px){
              max-width: 80%;
            }
            @media (max-width:991px){
              margin: 0;
            }
            
        }
         h3 {
          color: #000;
          margin: 30px 0 15px;
          @media (max-width:767px){
            font-size: 20px;
            line-height: 1.2;
            margin: 10px 0;
            }
          }
        p {
          margin-bottom: 0;
          color: #000;
          max-width: 560px;
          font-size: 19px;
          font-weight: 400;
          @media (max-width:1200px){
              max-width: 100%;
            }
      }
     a.link {
    color: #000;
    text-transform: uppercase;
    font-size: 20px;
    margin-top: 20px;
    display: inline-block;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: underline;
    text-underline-offset: 5px;
    @media (max-width:767px){
      font-size: 16px;
    }
    &:hover{
    }
}
      }
    }
  }


  .silverkaire-sec {
    margin-top: 0 !important;
    .inside-right-sec p {
    max-width: 390px !important;
}
.inner-img-div img {
   @media (max-width:1365px){
    max-width: 100px !important;
   }
   @media (max-width:767px){
    margin-inline: auto;
        display: flex;
   }
}
}


  .welcome_banner.welcome_banner_2 {
    .main-banner-section {
      min-height: 769px !important;
      .left-side-image {
        right: 0;
        left: auto;
        img {
            height: 100%;
            object-fit: cover;
        }
    }
    .inside-right-sec {
       padding-left: 0;
        p {
         margin-bottom: 0;
         color: #000;
         max-width: 352px;
         font-size: 19px;
         font-weight: 400;
         @media (max-width:1200px){
          max-width: calc(100% - 200px) !important;
        }
        @media (max-width:767px){
          max-width: 100% !important;
          margin-bottom: 20px;
        }
        }
      }
      .right-side-text {
        margin-left: 0;
        position: relative;
        .inner-img-div {
    position: absolute;
    bottom: 60px;
    right: 70px;
    @media (max-width:1600px){
      right: 20px;
    }
    @media (max-width:767px){
      position: static;
    }
}
    }
  }
}




  .pycn-section {
    .text-welcome {
      position: relative;
      left: -77px;
      top: 60px;
      z-index: 9;
    }
    .maritime-text {
      padding: 13px 26px;
      width: 100%;
      transform: unset;
      p {
        width: 100%;
      }
    }
  }
   
  .welcome-section {
    margin-top: 97px;
    padding: 0 0px;
    @media (max-width: 1024px) {
      margin-top: 68px;
    }

    .welcome_banner {
      @media (max-width: 767px) {
        float: left;
        width: 100%;
      } 
      .image-welcome {
        overflow: hidden; 
        height: auto;
        background-size: cover !important;
        position: relative;
        padding: 45px 0 12%;
        padding-top: 60px;
        background-position-y: -90px !important;
        background-repeat: no-repeat !important; 

        &.welcome-Carousel-banner {
          padding: 0;
          
          @media (max-width: 1280px) {
            padding: 0;
            max-height: unset;
            min-height: unset;
          }

          @media (max-width: 767px) {
            padding: 0 !important;
            margin-top: 112px;
            margin-bottom: 0;
          }

          .banner-slider {
            position: static;

            .slick-slider {
              .slick-slide {

                &>div {
                  display: flex;
                }
              }
            }
          }
        }
        
        @media (max-width: 1400px) {
          height: auto;
        }
        @media (max-width: 1280px) {
          max-height: 560px;
          padding: 40px 0 100px 0;
          background-position-y: 0 !important;
        }
        @media (max-width: 992px) {
          padding: 70px 0 100px;
          max-height: 430px;
        }
        @media (max-width: 1024px) {
          margin-top: 110px;    
          min-height: 480px;
        }
        @media (max-width: 991px) {
         min-height: auto;
        }
        @media (max-width: 767px) {
          margin-top: 0;  
          min-height: 430px;
          margin-bottom: 150px;
          overflow: visible;
          background: ${props => props?.promotionBannerMobile ? `url(${props?.promotionBannerMobile}) !important` : ``};
          padding-top: 150% !important;
        }

        @media (max-width: 1024px) {
          .col-md-3 {
            margin-top: 0;
            max-width: 360px;
          }
        }

        iframe {
          max-width: 100vw !important;
          width: 100vw !important;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          height: 180%;
          margin-top: -120px;
          @media (max-width: 1440px) {
            margin-top: -100px;
          }
          @media (max-width: 1366px) {
            margin-top: -80px;
          }
          @media (max-width: 1024px) {
            margin-top: -40px;
          }
          @media (max-width: 991px) {
            margin-top: 0px;
          }
          @media (max-width: 767px) {
            min-width: 210vw;
            width: 210vw !important;
          }
        }
      }
    }
  }
  .welcome-section.discount_banner{ 
        .welcome_banner { 
          .image-welcome {
            overflow: hidden;
            height: auto;
            background-size: cover !important;
            position: relative;
            padding: 30% 0 0;
            background-repeat: no-repeat !important;
            background-position: unset!important; 
            @media (max-width: 1199px){
              min-height: auto;
              margin-bottom: 0;
            }
            @media (max-width: 1024px){ 
              margin-top: 110px;
            }
            @media (max-width: 480px){
            margin-top: 0px;
            }
          }
        }
    }
  .image-welcome {
    margin-top: 0px;
    @media (max-width: 767px){
      
    }
    .frontend_image {
      img {
        position: static;
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }
    img {
      position: absolute;
      bottom: 120px;
      max-width: 40vw;
      right: 13vw;
      z-index: 9;
      @media (max-width: 1280px) {
        bottom: 70px;
      }
      @media (max-width: 991px) {
        max-width: 40vw;
        right: 7vw;
        bottom: 40px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        right: 0;
      }
    }
  }

  .image-welcome.vedio3 {
    height: 600px;
    overflow: hidden;
    position: relative;
  }
  .home-page .image-welcome.vedio3 iframe {
    max-height: 662px;
    margin-top: 0;
    max-width: 100vw !important;
    width: 100vw !important;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .welcome-section.pycn-section {
    margin-top: 0;
  }
  .question-text h1 {
    margin: 0 0 70px;
  }
  .welcome-section.gauratee-section {
    padding: 50px 0 5px;
    margin-top: 0px;
  }
  .carousel img {
    object-fit: contain;
  }

  .vedio3 {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    iframe {
      max-width: 100vw !important;
      width: 100vw !important;
      height: 728px;
      line-height: 1;
      display: block;
      min-width: 1025px !important;
      min-height: 622px !important;
    }
  }
  .vedio2 {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    iframe {
      max-width: 100% !important;
      width: 100% !important;
      height: 728px;
      line-height: 1;
      display: block;
      min-width: 1105px !important;
      min-height: 622px !important;
    }
  }

  .home-page .image-welcome.vedio2 iframe {
    max-width: 1105px !important;
    width: 100% !important;
    height: 923px;
    max-height: 596px;
  }

  // 18-02-2022
  .container.order-detail-page.p-4 .couponstyle__CoupanStyle-sc-1y1fifq-0 {
    padding: 10px 40px;
  }

  .container.order-detail-page.p-4 h3 {
    background: rgb(0, 53, 106);
    margin: 0px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 15px 0px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-weight: 500;
  }

  .distributed_customer {
    margin: 30px 0 0;
  }
  .coupon_list_table th,
  .coupon_list_table td {
    font-size: 14px;
  }
  .container.order-detail-page.p-4 button:not(:last-child) {
    margin-right: 10px;
  }
  .order_table.address-list.order-list.auto-ship-order-table
    table.commission-table
    td.autoship-action
    button {
    background: transparent;
    border: none;
    svg {
      width: 17px;
      height: 17px;
      margin: 0;
      fill: #ffaa0f;
    }
  }
  td.autoship-action {
    align-items: center;
    display: flex;
  }
  .orders_date {
    display: flex;
    justify-content: space-between;
  }

  .product-main {
    .radio_class.selected_class {
      background: #c3cfde;
    }
    .radio_class {
      align-items: center;
      border-bottom: 1px solid #b3b3b3;
      min-height: 75px;
      color: #000;
      display: flex;
      &:first-child {
        border-top: 1px solid #b3b3b3;
      }
    }
    .second_section .radio_class {
      min-height: auto;
    }
    .title_section {
      text-align: left;
      width: 100%;
      h3 {
        font-size: 18px;
        text-transform: capitalize;
        font-family: "Graphik";
        margin-bottom: 2px;
        margin-top: 7px;
      }
      p {
        font-size: 16px;
        margin-bottom: 7px;
        line-height: normal;
      }
      button.btn.btn-larn {
        height: 36px;
        max-width: 120px;
        font-size: 14px;
        margin-top: 15px;
      }
    }
    .price_section {
      padding-right: 10px;
      width: 100%;
      max-width: max-content;
    }
    .price_section div {
      font-size: 14px;
      padding-right: 0;
      font-family: "Graphik-bold";
    }

    .content-product {
      padding-bottom: 15px;
      button.btn.btn-cart {
        color: #fff;
        margin-top: 10px;
        max-width: fit-content;
        padding: 8px 20px;
        border-radius: 30px;
        font-weight: 500;
        border: 2px solid var(--blue);
        &:hover{
          background-color: #fff;
          color: var(--blue);
        }
      }
      .second_section {
        .radio_class.custom_radio {
          padding: 8px 0;
        }
        .normal_radio_container.radio_class.custom_radio {
          &.add_to_the_class {
            padding-bottom: 25px;
          }
          h3.order {
            margin: 0;
          }
          span.MuiRadio-root {
            margin-top: 5px;
          }
          p.green_code {
            margin-bottom: 0;
          }
        }
        span.discount_smartship {
          font-size: 12px;
          bottom: 10px;
          padding-right: 0;
          /* @media (max-width: 767px){

} */
        }
      }
    }
  }
  .content-product {
    .normal_radio_container.radio_class.custom_radio.selected_class.add_to_the_class {
      padding-bottom: 25px;
    }
  }

  @media (max-width: 1320px) {
    .text-welcome h2 {
      font-size: 46px;
    }
    .product-main1 img {
      width: 100px;
    }
    .welcome-text.maritime-text h4 {
      font-size: 28px;
    }
  }
  @media (max-width: 1400px) {
    .welcome-section.gauratee-section .image-welcome iframe {
      min-width: 1192px !important;
    }
    .home-page .image-welcome {
      height: 570px;
    }
  }
  @media (max-width: 1366px) {
    .welcome-section.gauratee-section .welcome-text {
      padding: 5px 10px 20px;
      width: 380px;
      transform: translate(0px, 55px);
    }
    .pycn-section {
      .image-welcome {
        min-width: 1000px;
      }
      .text-welcome {
        position: relative;
        left: 0px;
        top: 0px;
      }
    }
    .text-welcome h2 {
      font-size: 52px;
    }
    .home-page .image-welcome {
      height: 560px;
    }
  }
  @media (max-width: 1280px) {
    .home-page .image-welcome {
      height: 520px;
      background-size: cover !important;
    }
  }
  @media (max-width: 1100px) {
    .text-welcome h2 {
      font-size: 35px;
    }
    .pycn-section .image-welcome {
      min-width: 780px;
    }
    .question-text h1 {
      margin: 0 0 90px;
      font-size: 45px;
      padding: 0;
    }
    .stay-touch-part h5 {
      top: 43px;
      margin-left: 0;
    }
    .stay-touch-part {
      max-width: 100%;
    }
    .form-subscribe {
      text-align: left;
    }
    // input#staticEmail {
    //     width: 66%;

    // }
    .form-subscribe .form-group {
      width: 92%;
    }
    .welcome-text.maritime-text h4 {
      font-size: 25px;
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 1199px) {
    .with_animation {
      display: none;
    }
    .without_animation {
      display: block;
    }
    /* .welcome-section {
      margin-top: 68px !important;
    }
  */
    .home-page .image-welcome {
      height: 490px;
      overflow: hidden;
    }
    .welcome-section.gauratee-section .image-welcome iframe {
      min-width: 1189px !important;
      min-height: 632px !important;
    }
  }

  @media (max-width: 991px) { 
    .text-welcome h2 {
      margin: 15px 0 15px;
      float: left;
      color: #000;
    }
    .welcome-section .text-welcome {
      transform: translateY(0);
    }
    .pycn-section .image-welcome {
      min-width: 100%;
    }
    .welcome-text.maritime-text h4 {
      width: 100%;
    }
    .welcome-text {
      width: 100%;
      text-align: center;
      padding: 24px 49px;
    }
    .par-text {
      width: 100%;
      margin: 34px auto 0;
      max-width: 100%;
      @media (max-width: 767px) {
        padding: 0 10px;
      }
    }
    .welcome-section.gauratee-section {
      padding: 0px 0 15px;
    }
    .welcome-section.gauratee-section p {
      width: 100%;
      margin: 0 auto;
    }
    .question-text {
      margin-top: 6px;
    }
    .stay-touch-part h5 {
      top: 59px;
    }
    .welcome-text p {
      font-size: 15px;
    }
    .par-text p {
      font-size: 15px;
    }
    .product-main1 img {
      width: 100%;
      max-width: 300px;
    }
    .slider-main a.btn.btn-larn {
      font-size: 14px;
      line-height: 23px;
    }
    .btn-shop-all a {
      font-size: 14px;
      line-height: 23px;
    }
    .welcome-section.gauratee-section .welcome-text p {
      font-size: 14px;
    }
    .question-text h1 {
      color: #000;
      margin: 0 0 44px;
      font-size: 35px;
    }
    .welcome-section {
      margin-top: 0px !important;
    }
    .pycn-section .text-welcome,.welcome-section .image-welcome {
      /* float: left; */
      width: 100%;
    }
     
    .slider-main {
      margin: 60px 0 30px;
    }
    .slider-main .slider-main.mt-3 {
      margin: 0px auto;
    }
    .welcome-text {
      transform: translate(0px);
    }

    .home-page .image-welcome {
      height: 300px;
    }
    .welcome_banner + .row {
      width: 100%;
      display: flex;
      margin: 0 auto 25px;
    }
    .welcome-section.gauratee-section .welcome-text {
      padding: 15px 0px;
      width: 100%;
      transform: translate(0px);
      margin-bottom: 18px;
    }
  }

  @media (max-width: 942px) {
    .form-subscribe .btn {
      margin-left: 0;
    }
    .form-subscribe label {
      font-size: 27px;
      font-weight: 800;
    }
    .form-subscribe .form-group {
      display: flex;
    }
    input#staticEmail {
      width: 100%;
      margin: 0 0 0 20px;
    }
    .form-subscribe .form-group {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .form-subscribe .btn.btn-primary {
      width: 100%;
      margin: 0 auto;
    }
    .form-subscribe .form-group {
      display: flex;
      margin-bottom: 0px;
    }
    .form-subscribe .form-group {
      width: 100%;
    }
    input#staticEmail {
      width: 100%;
    }
    .product-main h5 {
      font-size: 13px;
    }
    .welcome-section.gauratee-section p {
      padding: 15px;
    }
    .form-subscribe label {
      font-size: 27px;
      padding-top: 14px;
    }
    .stay-touch-part h5 {
      top: 63px;
      font-size: 15px;
    } 
    .welcome-text {
      padding: 24px;
    }
  }

  @media (max-width: 480px) {
    .welcome-text p {
      font-size: 13px;
      line-height: 22px;
    }
    .par-text p {
      font-size: 13px;
      line-height: 24px;
    }
    .welcome-section.gauratee-section .welcome-text p {
      font-size: 13px;
    }

    .question-text h1 {
      font-size: 26px;
      padding: 0;
    }
    .stay-touch-part h5 {
      top: 35px;
    }
    .form-subscribe label {
      font-size: 28px;
      font-weight: 800;
    }
    /* .welcome-section {
      padding: 110px 0 0;
    } */
    .welcome-section.pycn-section {
      padding: 0;
    }
    .welcome-text.maritime-text h4 {
      font-size: 20px;
    }
    .welcome-text h4 {
      font-size: 20px;
    }
    .text-welcome h2 {
      font-size: 20px;
    }
    .form-subscribe label {
      font-size: 20px;
      font-weight: 800;
    }
    .home-page .image-welcome {
      position: relative;
      height: 200px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      @media (max-width: 767px) {
        height: auto;
        /* padding: 10px 0; */
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        width: 100%;
        justify-content: center;
        align-items: center;
        align-content: center; 
      } 
    }
    .slider-main {
      margin: 30px 0;
    }
    .welcome-section.gauratee-section .text-welcome {
      transform: unset !important;
    } 
    .welcome-text.maritime-text {
      margin-top: 20px;
    }
    .welcome-section.gauratee-section .image-welcome {
      height: 250px;
      iframe {
        min-width: 100vw;
        height: auto !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 250px;
        min-height: 250px !important;
        margin: 0 !important;
      }
    } 
    .welcome-section.pycn-section {
      .image-welcome.vedio3 {
        height: 250px;
      }
      .image-welcome.vedio2 {
        height: 250px;
        margin-top: 20px;
        iframe {
          min-width: 455px !important;
          min-height: 255px !important;
        }
      }
    }
  }

  @media (min-width: 767px) {
    .slider-main * {
      overflow: visible !important;
    }
    .slider-main .app_active svg.MuiSvgIcon-root,
    .slider-main .app svg.MuiSvgIcon-root {
      font-size: 30px;
    }
  }

  .content-product {
    padding-bottom: 20px;
    position: absolute;
    z-index: 9;
    top: 100%;
    background: #fff;
    width: 100%;
  }

  .slider-main {
    z-index: 9;
    position: relative;
  }

  .product-main {
    position: relative;
  }
  .home-page {
    overflow-x: hidden;
  }

  @media (max-width: 991px) {
    .product-main:hover .content-product {
      transform: translateY(-100px);
      visibility: visible;
      opacity: 0;
      transition: 0.3s ease all;
      transform-origin: top;
    }
    .product-main .app_active + .content-product {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 767px) {
    .product-main > div h5 + svg {
      width: 2em;
      height: 2em;
    }
    .slider-main .carousel-inner * {
      overflow: visible !important;
    }
    .welcome_banner .text-welcome .welcome-text {
      transform: translateY(420px);
      width: 100%;
      min-width: calc(100vw - 35px);
      display: block;
    }
    .fotter-list ul li a {
      font-size: 13px;
    }
    .welcome_banner + .row {
      margin: 0 auto 0px;
    }
    .welcome-section.gauratee-section .welcome-text {
      margin-bottom: 30px;
      transform: translate(0px, 0px);
    }
  }
  .product-main .content-product span {
    display: block;
    width: 100%;
    margin-top: 0px;
  }

.main-banner-section {
    display: flex;
    @media (max-width:1200px){
      flex-wrap: wrap;
    }
}

.left-side-image {
    flex-basis: 100%;
    
    img {
        width: 100%;
    }
}

.main-banner-section {
    display: flex;
}

.left-side-image,
.right-side-text {
    flex: 1 1 50%;
}

.inside-right-sec {
    padding: 20px 0px 20px 90px; 
    @media (max-width:1365px){
      padding: 20px 0px 20px 40px; 
    }
    @media (max-width:1200px){
      padding: 20px 0px 60px 0px;
      width: 100%; 
    }
}


 .biotickakire-video-sec {
    padding: 40px 0 50px;
    clear: both;
    background-color: #fff;
    @media only screen and (max-width:767px) {
      padding: 40px 0 40px;
    }

    .container {
      margin: 0 auto;
    }

    .biotickakire-video-wrap {
      text-align: center;

      h2 {
        margin: 0 0 30px;
        font-size:42px;
        color: #000;
        max-width: 770px;
        margin-inline: auto;
        line-height: 1.4;
        font-weight: 400;
        @media only screen and (max-width:1365px) {
          font-size:35px;
        }

        @media only screen and (max-width:767px) {
          margin: 0 0 20px;
          font-size:26px;
        }
        @media only screen and (max-width:576px) {
          font-size:22px;
        }
      }

      .biotickakire-video-innerwrap {
        display: flex;
        gap: 106px;
        max-width: 890px;
        margin: auto;

        @media only screen and (max-width:767px) {
          gap: 20px;
        }
        @media only screen and (max-width:575px) {
          flex-direction: column;
          align-items: center;
        }

        .biotickakire-video {
          width: calc(100% / 3 - calc(60px / 3));
          height: 440px;
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          @media only screen and (max-width:991px) {
            height: 296px;
            border-radius: 12px;
          }
          @media only screen and (max-width:767px) {
            height: 300px;
            width: calc(100%/3 - calc(40px/3));
          }
          @media only screen and (max-width:575px) {
            width: 216px;
            height: 386px;
          }

          iframe {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .banner-slider {
    background-color: #fff;
    padding: 50px 0;
     .slider-wrap {
        max-width: 1320px;
        margin-inline: auto;
        .slick-slide {
          /* margin:  0 15px; */
          .image-crousel {
             background-image: linear-gradient(0deg, #5780e4 0%, #5780e4 -0%, #ca8099 100%);
             min-height: 400px;
             padding: 30px;
             @media (max-width:767px){
              min-height: 300px;
             }
            }
        }
  }
  & .prev-arrow img, & .custom-next img {
    width: 18px !important;
  }
    h2 {
      margin: 0 0 30px;
    font-size: 42px;
    color: #000;
    margin-inline: auto;
    line-height: 1.4;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    @media (max-width:1365px){
      font-size: 35px;
    }
    @media (max-width:767px){
      font-size: 26px;
    }
  }
  .slick-slider {
      position: relative;
      padding-inline: 30px;
     & .custom-prev, & .custom-next  {
          position: absolute;
          top: 50%;
          width: auto !important;
          height: auto !important;
          left: 0;
          cursor: pointer;
      }
      .custom-next {
          right: 0;
          left: auto;
    }
   & > div {
    /* padding: 15px; */
}
.slick-list>.slick-track>.slick-slide>div {
    padding: 0 15px;
}
  }
  .image-crousel {
    .product-info {
     color: #ffff;
     h3 {
    font-size: 25px;
    letter-spacing: -1px;
    line-height: 29px;
    color: #ffffff;
    font-weight: bold;
    font-family: "Graphik";
    text-align: center;
    margin: 4px 0 15px;
}
p {
    margin-bottom: 0;
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 27px;
    color: #ffffff;
    font-family: "Graphik";
    @media (max-width:767px){
      font-size: 18px;
      line-height: 25px;
    }
}
  }
  .star-rating {
    display: flex;
    justify-content: center;
    span.star {
    font-size: 40px;
}

}

}
 }



.biotickaire-sec {
  margin-top:0;
  .inner-img-video-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    @media (max-width:1200px){
      max-width: 620px;
      margin-inline: auto;
    }
    @media (max-width:567px){
      flex-wrap: wrap;
      justify-content: center;
    }
    & > img {
    max-width: 180px;
    height: 279px !important;
    object-fit: cover;
    @media (max-width:1600px){
      height: 239px !important;
    }
    @media (max-width:1365px){
      height: 229px !important;
    }
    @media (max-width:767px){
      height: 229px !important;
    }
}
.biotickakire-video-innerwrap {
    display: flex;
    gap:15px;
    & > .biotickakire-video {
    width: calc(100% / 3);
    & iframe {
    width: 100%;
    height: 268px;
    border-radius: 20px;
    @media (max-width:1600px){
      height: 208px;
    }
    @media (max-width:1365px){
      height: 185px;
      border-radius:12px;
    }
    @media (max-width:1200px){
      height: 235px;
    }
    @media (max-width:767px){
      height: 200px;
    }
    @media (max-width:576px){
      height: auto;
    }
}
}
}
}
}




.guarrented-section {
    background-color: #fff;
    padding: 60px 0;
    .top-image {
    display: flex;
    justify-content: center;
}
.garreanted-inner-div {
    max-width: 1120px;
    margin-inline: auto;
    p {
    margin: 0;
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 29px;
    color: #000000;
    font-family: "Graphik";
    text-align: center;
    @media (max-width:767px){
      font-size: 18px;
    }
    a {
    text-decoration: underline;
    text-underline-offset: 8px;
    color: #0071bc;
}
}
p.first-text {
    max-width: 670px;
    margin: 30px auto;
    @media (max-width:767px){
      margin: 20px auto;
    }
}
& p.last-text {
    line-height: 1.7;
}

}

}

.home-main-qty-sec {
  button {
    display:block !important
  }
}

.slider-main.slider-main-wrapper {
    margin: 0;
    background-image: linear-gradient(0deg, #ffffff 0%, #f0f0f0 100%);
    padding: 50px 0 50px;
    border: 1px solid #b3b3b3;
    .product-main {
    padding: 0;
    background: transparent;
    h5 {
      margin: 5px 0 15px;
      font-size: 26px;
      letter-spacing: 0px;
      line-height: 30px;
      color: #000000;
      font-family: "Graphik";
      text-align: center;
      @media (max-width:1600px){
        font-size: 24px;
      }
      @media (max-width:1365px){
        font-size: 20px;
      }
      }
    }    
.radio_class {
    /* padding: 10px 0; */
    opacity: 0.6;
    h3.order {
    margin: 0 0 4px 0;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 20px;
    color: #000000;
    font-family: "Graphik";
    @media (max-width:1365px){
      font-size: 16px;
    }
}
p {
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 20px;
    color: #000000;
    font-family: "Graphik";
    @media (max-width:1365px){
        font-size: 14px;
      }
}
.price_section div {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 20px;
    color: #000000;
    font-weight: bold;
    font-family: "Graphik";
    @media (max-width:1365px){
        font-size: 14px;
      }
}

&.selected_class {
    opacity: 1;
}
}
.slick-slide {
    padding: 0 6px;
}
.home-main-qty-sec {
  #qty {
    display: flex;
    justify-content: center;
    padding: 15px 0 5px;
    button {
    background: transparent;
    padding: 0;
    font-size: 22px;
    font-weight: 400;
    padding-inline: 10px;
    color: #000;
}
input {
    max-width: 90px;
    border: unset;
    text-align: center;
    border-bottom: 1px solid #000;
    background: transparent;
    font-size: 18px;
    color: #000;
}
}
}
.main_btn_common {
    text-transform: uppercase;
}
}
.proven-benefits.section {
    padding: 60px 0;
    background-color: #fff;
    .container {
    max-width: 1570px !important;
    margin: 0 auto;

     h2 {
    margin: 0x;
    font-size: 42px;
    color: #000;
    margin-inline: auto;
    line-height: 1.4;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-weight:600;
    @media (max-width:1200px){
      font-size: 38px;
    }
    @media (max-width:991px){
      font-size: 35px;
    }
    @media (max-width:767px){
      font-size: 30px;
      margin-bottom:0x;
    }
}
p{
  text-align: center;
  margin-bottom:60px;
  @media (max-width:767px){
      margin-bottom:30px;
    }
  
}
.slick-list {
    max-width: 1390px;
    margin-inline: auto;
    padding-bottom: 30px;
}
.inner-icon {
  display: flex !important;
    flex-direction: column;
    align-items: center;
 a {
    color: #0071bc;
    font-size: 20px;
    line-height: normal;
    margin-top: 5px;
    text-decoration: underline;
    text-underline-offset: 5px;
}
  svg {
    height: 120px;
    @media (max-width:767px){
      height: 60px;
    }
   path {
    fill: #01356a;
}
}
h4 {
    margin: 0;
    font-size: 20px;
    color: #000000;
    text-align: center;
    margin-top: 20px;
    @media (max-width:767px){
      font-size: 18px;
    }
}
}
.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    cursor: pointer;
    @media (max-width:767px){
      left: -10px;
    }
    img {
      @media (max-width:767px){
    width: 10px;
  }
}
}
 .custom-next {
    right: 0;
    left: auto;
    @media (max-width:767px){
      right: -10px;
    }
}
}
}


.welcome-section.Prime-sec {
    margin-top: 0;
    border: none !important;

    .inner-img-div img {
      @media (max-width:1365px){
        max-width: 160px;
      }
      @media (max-width:1200px){
        max-width: 130px;
      }
      @media (max-width:767px){
        display: flex;
        margin-inline: auto;
      }
}
}
.inner-icon a {
    color: #0071bc;
    font-size: 20px;
    line-height: normal;
    margin-top: 5px;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.main-banner-section .left-side-image {
    position: relative;
}

.main-banner-section .left-side-image h3.top-h3 {
    position: absolute;
    top: 50px;
    margin: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #fff;
    font-size: 120px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    padding-inline: 15px;
    @media (max-width:1600px){
      font-size: 80px;
    }
    @media (max-width:991px){
      font-size: 60px;
      line-height:1;
    }
    @media (max-width:767px){
      font-size: 40px;
      top: 15px;
    }
}
@media (max-width:1200px){
  .welcome-section.Prime-sec .main-banner-section, .welcome-section.biotickaire-sec .main-banner-section, .welcome-section.silverkaire-sec .main-banner-section {
    padding-top: 50px;
}
}
@media (max-width:991px){
  .home-page>.welcome-section {
    margin-top: 112px !important;
}
.home-page .welcome-section.biotickaire-sec, .home-page .welcome-section.silverkaire-sec, .home-page .welcome-section.Prime-sec {
    margin-top: 0 !important;
}
}

@media (max-width:767px){
  .home-page .welcome-section.Prime-sec, .welcome-section.biotickaire-sec, .welcome-section.silverkaire-sec{
    display: flex;
  }
  .welcome-section.Prime-sec .btn-link, .welcome-section.silverkaire-sec .btn-link{
    display: flex;
    justify-content: center;
}
.proven-benefits.section .custom-arrow img {
    width: 12px;
}
}
.lazy-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.biotickakire-video-sec .biotickakire-video-maon-section {
    flex: 1;
    border-radius: 20px;
    overflow: hidden;
}
.biotickakire-video-sec .biotickakire-video-wrap .biotickakire-video-innerwrap .biotickakire-video>p {
    margin: 0;
    color: #000;
    font-weight: 600;
}

.btn-link.mobile-view{
  display: none;
} 

@media (max-width:767px){
  .inner-img-video-wrapper .btn-link.mobile-view {
    width: 100%;
    display: flex;
    justify-content: center;
}

.inner-img-video-wrapper .btn-link.mobile-view button {
  margin-top: 0;
}
.inner-img-video-wrapper {
    flex-wrap: wrap;
    justify-content: center;
}
.right-side-text .btn-link {
    display: none;
}
}
.prime-video-data {
    position: absolute;
    top: 100px;
    right: 100px;
    padding-bottom: 30%;
    max-width: 130px;
    width: 100%;
    @media (max-width:1600px){
      right: 50px;
      max-width: 120px;
    }
    @media (max-width:1365px){
      right: 40px;
      max-width: 100px;
      top: 300px;

    }
    @media (max-width:1200px){
        top: 10px;
        padding-bottom: 18%;
      
    }
    @media (max-width:991px){
        top: 30px;
        padding-bottom: 23%;
        right: 0;
        max-width: 100px;
    }
    @media (max-width:767px){
        top: auto;
        padding-bottom: 33%;
        right: 10px;
        max-width: 100px;
        bottom: 100px;
    }
    @media (max-width:550px){
      padding-bottom: 34%;
        right: 10px;
        max-width: 100px;
        bottom: 100px;
        }    
      iframe {
      position: absolute;
      width: 100%;
      border-radius: 20px;
      height: 100%;
}
}

.silver-video-data {
    position: absolute;
    top: 105px;
    right: 79px;
    padding-bottom: 30%;
    max-width: 130px;
    width: 100%;
    @media (max-width:1600px){
    top: 155px;
    right: 30px;
    max-width: 120px;
    }
    @media (max-width:1365px){
      top: 270px;
        right: 30px;
        max-width: 100px;
    }
    @media (max-width:1200px){
        top: 158px;
        right: 138px;
        max-width: 100px;
        padding-bottom: 18%;
    }
    @media (max-width:991px){
        top: 7px;
        right: 36px;
        max-width: 73px;
        padding-bottom: 16%;
    }
    iframe {
    position: absolute;
    width: 100%;
    border-radius: 20px;
    height: 100%;
    }
    
}

.prime-video-data.mobile-view {
display: none;
}

@media (max-width:767px){
  .prime-video-data.desktop-view {
    display: none;
}

.prime-video-data.mobile-view {
    display: block;
    position: static;
    padding: unset;
    height: auto;
    margin-inline: auto;
    max-width: 92px;
    margin-bottom: 20px;
}
.prime-video-data.mobile-view iframe {
    position: static;
    height: auto;
}
}
h3.top-h3.heading-font img{
  width: 100%;
  max-width: 545px;
  @media (max-width:767px){
    max-width: 62%;
}
}

`;

export default HomeComponent;
